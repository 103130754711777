import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plans-page-background complex-bg" }
const _hoisted_2 = { class: "complex-bg__icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayoutIcon, {
        name: "brushBlue",
        id: "brushBlue"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushPink",
        id: "brushPink"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsWhite",
        id: "dotsWhite-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "stars5Yellow",
        id: "stars5Yellow"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "legal",
        id: "legal"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "heart",
        id: "heart"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "ideas2",
        id: "ideas2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsWhite",
        id: "dotsWhite-right"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "userBadge",
        id: "userBadge"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "tiktokBadge",
        id: "tiktokBadge"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "squiggle",
        id: "squiggle"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "playButton",
        id: "playButton"
      })
    ])
  ]))
}