
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import PlanCard from "@/shared/components/PlanCard.vue";
import { useSubscriptionPlans } from "@/shared/composables/useSubscriptionPlans";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import ModalPrompt from "@/shared/components/ModalPrompt.vue";
import routeNames from "@/web/router/routeNames";
import { useRouter } from "vue-router";
import { CommonAlertTypes } from "@/shared/utils/alert";
import { openInNewTab } from "@/shared/utils/browser";
import { config } from "@/shared/utils/config";
import PlansBackground from "./PlansBackground.vue";
import {
  analyticsTrackSubscriptionPlanAdd,
  analyticsTrackSubscriptionPlanChange,
} from "@/shared/utils/analytics";

const planBackgrounds = ["planBgBlue", "planBgYellow", "planBgPink"];

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();

    // STATE
    const successModal = ref();
    const currentPlan = computed(() => store.state.auth.subscriptionPlan);

    // QUERIES
    const {
      subscriptionPlans,
      subscriptionPlansLoading,
      currentSubscriptionPlanLoading,
      subscribeToPlan,
    } = useSubscriptionPlans();

    // METHODS
    const handleSubscribeToPlan = async (plan) => {
      if (currentPlan.value?.id) {
        analyticsTrackSubscriptionPlanChange(
          currentPlan.value?.name ?? "",
          plan.name
        );
      } else {
        analyticsTrackSubscriptionPlanAdd(plan.name);
      }

      await subscribeToPlan(plan.id);
      successModal.value.openModal();
    };

    const handleCTA = () => {
      openInNewTab(config.contactUrl);
    };

    const handleCartRedirect = () => {
      router.push({ name: routeNames.cart });
    };

    return {
      t,
      subscriptionPlans,
      subscriptionPlansLoading,
      currentSubscriptionPlanLoading,
      currentPlan,
      handleCTA,
      handleCartRedirect,
      handleSubscribeToPlan,
      routeNames,
      successModal,
      CommonAlertTypes,
      planBackgrounds,
    };
  },
  components: { PageLayout, PlanCard, ModalPrompt, PlansBackground },
});
