
import { SubscriptionPlans_subscriptionPlans_SubscriptionPlanResults_results } from "@/api/plans/__generated__/SubscriptionPlans";
import LayoutIcon from "@/shared/components/Icons/LayoutIcon.vue";
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t, n } = useI18n();

    return { t, n };
  },
  props: {
    plan: {
      type: Object as PropType<SubscriptionPlans_subscriptionPlans_SubscriptionPlanResults_results>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    backgroundIconName: {
      type: String,
      required: true,
    },
  },
  components: { LayoutIcon },
  emits: ["select-plan"],
});
