import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-card" }
const _hoisted_2 = { class: "plan-card__header" }
const _hoisted_3 = { class: "plan-card__header__text" }
const _hoisted_4 = { class: "plan-card__content" }
const _hoisted_5 = { class: "plan-card__detail-list" }
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayoutIcon, {
        name: _ctx.backgroundIconName,
        class: "plan-card__header__bg"
      }, null, 8, ["name"]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_a_typography_title, { level: 3 }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.plan.name), 1)
          ]),
          _: 1
        }),
        (Number(_ctx.plan.price))
          ? (_openBlock(), _createBlock(_component_a_typography_title, {
              key: 0,
              level: 3
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.t("{planPrice} monthly", {
              planPrice: _ctx.n(Number(_ctx.plan.price), {
                locale: "en-US",
                key: "currency",
              }),
            })), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.plan.description.split('\n'), (detail) => {
          return (_openBlock(), _createElementBlock("div", {
            key: detail,
            class: "plan-card__detail-item"
          }, [
            _createVNode(_component_LayoutIcon, { name: "checkmark" }),
            _createTextVNode(" " + _toDisplayString(detail), 1)
          ]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_a_button, {
          type: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('select-plan', _ctx.plan))),
          disabled: _ctx.selected
        }, {
          default: _withCtx(() => [
            (_ctx.selected)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.t("This is your plan")), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.t("Get this plan")), 1))
          ]),
          _: 1
        }, 8, ["disabled"])
      ])
    ])
  ]))
}