import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "plans-page__cta" }
const _hoisted_2 = { class: "text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_PlansBackground = _resolveComponent("PlansBackground")!
  const _component_PlanCard = _resolveComponent("PlanCard")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PageLayout = _resolveComponent("PageLayout")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_ModalPrompt = _resolveComponent("ModalPrompt")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.subscriptionPlansLoading)
      ? (_openBlock(), _createBlock(_component_a_skeleton, {
          key: 0,
          active: ""
        }))
      : (_ctx.subscriptionPlans)
        ? (_openBlock(), _createBlock(_component_PageLayout, {
            key: 1,
            class: "plans-page",
            title: _ctx.t('Choose your plan'),
            narrow: ""
          }, {
            complexBackground: _withCtx(() => [
              _createVNode(_component_PlansBackground)
            ]),
            default: _withCtx(() => [
              _createVNode(_component_a_row, {
                gutter: [42, 42],
                class: "plans-page__plans"
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.subscriptionPlans, (plan, i) => {
                    return (_openBlock(), _createBlock(_component_a_col, {
                      key: plan?.id,
                      span: 24,
                      md: 12,
                      xl: 8,
                      wrap: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PlanCard, {
                          plan: plan,
                          selected: _ctx.currentPlan?.id === plan?.id,
                          onSelectPlan: _ctx.handleSubscribeToPlan,
                          "background-icon-name": _ctx.planBackgrounds[i % _ctx.planBackgrounds.length]
                        }, null, 8, ["plan", "selected", "onSelectPlan", "background-icon-name"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }),
              _createElementVNode("div", _hoisted_1, [
                _createTextVNode(_toDisplayString(_ctx.t("Still not yet sure?")) + " ", 1),
                _createVNode(_component_a_button, {
                  type: "text",
                  class: "plans-page__cta-btn",
                  onClick: _ctx.handleCTA
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Talk to us.")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_a_button, {
                  type: "text",
                  class: "plans-page__back-btn normal-letter-spacing",
                  onClick: _ctx.$router.back
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Let me explore more. Take me back.")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ]),
            _: 1
          }, 8, ["title"]))
        : _createCommentVNode("", true),
    _createVNode(_component_ModalPrompt, {
      actions: [
      {
        title: _ctx.t('continue creating'),
        action: _ctx.handleCartRedirect,
        type: 'primary',
      },
    ],
      icon: _ctx.CommonAlertTypes.success,
      title: _ctx.t('Yey! Excited to create with you!'),
      ref: "successModal"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t(
          "Please expect the checkout link in the email you provided in the registration."
        )), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("If you want to change your plans, just go to your profile!")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_paragraph, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Should you want to talk to us, just hit us up through email.")), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["actions", "icon", "title"])
  ], 64))
}